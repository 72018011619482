@import '../../../../scss/theme-bootstrap';

@font-face {
  font-family: 'OptimaDisplayBook';
  src: url('#{$netstorage-font-path}OptimaDisplay/optimadisplay-book-webfont.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

$blogarticle-max-width: 1144px;
$max-mob-width: 767px;
$optima-book: 'OptimaDisplayBook';
$vid-play-white: url(/media/export/cms/discover/icons/vid_play_white.svg);
$vid-play-navy: url(/media/export/cms/discover/icons/vid_play_navy.svg);
$vid-play-black: url(/media/export/cms/discover/icons/vid_play_black.svg);
$youtube-close: url(/media/boutiques/common/video_close_x_white.svg);

// Blog Article
.blog-article {
  padding-top: 3.2%;
  .ytPlayer__icon,
  .video_content_v1 .video_content_cta_outer {
    @media screen and (max-width: $max-mob-width) {
      margin-#{$ldirection}: -4.3%;
    }
    margin-#{$ldirection}: -2.2%;
    &.navy,
    .video_content_cta.show.dark {
      background-image: $vid-play-navy;
    }
    &.black {
      background-image: $vid-play-black;
    }
    &.white,
    .video_content_cta.show.light {
      background-image: $vid-play-white;
    }
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    #{$ldirection}: 50%;
    cursor: pointer;
    z-index: 3;
  }
  .ytPlayer__icon {
    @media screen and (max-width: $max-mob-width) {
      width: 8.75vw;
      height: 9.84375vw;
    }
    background-image: $vid-play-white;
    width: 49px;
    height: 55px;
    &--only {
      cursor: default;
      z-index: 1;
    }
  }
  .video_content_v1 {
    .video_content_cta_outer {
      background-image: none;
      .video_content_cta {
        @media screen and (max-width: $max-mob-width) {
          width: 8.75vw;
          height: 9.84375vw;
        }
        width: 49px;
        height: 55px;
        display: block;
      }
    }
  }
  .ytPlayer {
    .ytPlayer__img {
      background-image: $youtube-close;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      vertical-align: inherit;
      z-index: 996;
      height: 6.2%;
      top: 0;
      img {
        visibility: hidden;
      }
    }
  }
  @media screen and (max-width: $max-mob-width) {
    padding-top: 4.2%;
  }
  margin: auto;
  width: 100%;
  max-width: $blogarticle-max-width;
  position: relative;
  overflow: hidden;
  font-size: 100%;
  cursor: default;
  @media screen and (min-width: $large-min) {
    overflow: visible;
  }
  nav.breadcrumbs {
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      @media screen and (min-width: $mobile-width-limit) and (max-width: $medium-max) {
        margin-top: 15px;
      }
      .breadcrumbs__level:first-child:before {
        display: none;
      }
    }
    @media screen and (min-width: $large-min) {
      margin-#{$ldirection}: calc(-100vw / 2 + (#{$blogarticle-max-width} + 17px) / 2);
      &:first-child {
        margin-top: 5px;
      }
    }
    @media screen and (min-width: $large-min) and (max-width: $blogarticle-max-width) {
      margin: 0 auto;
      &:first-child {
        margin-top: 13px;
      }
    }
    @media screen and (min-width: $large-max) {
      &:first-child {
        margin-top: -0.3em;
      }
    }
  }
  .blog-article__social-share--mob {
    @media screen and (max-width: $max-mob-width) {
      ul {
        margin-bottom: 14px;
      }
    }
    @media screen and (max-width: $medium-max) {
      display: block;
      visibility: visible;
    }
    display: none;
    visibility: hidden;
  }
  .blog-article__social-share--desktop {
    @media screen and (max-width: $medium-max) {
      display: none;
      visibility: hidden;
    }
  }
  .blog-article__social {
    position: absolute;
    z-index: 2;
    #{$ldirection}: 7%;
    .blog-article__social {
      &-wrapper {
        position: relative;
        display: inline-block;
        margin: 0 auto 37px;
        .blog-article__social-container {
          position: absolute;
          #{$ldirection}: auto;
          top: 197px;
          .ie & {
            height: 150px;
          }
          .blog-article__social-share {
            position: absolute;
            margin: auto;
            top: 0px;
            #{$ldirection}: 0px;
            bottom: auto;
            #{$rdirection}: auto;
            li {
              position: relative;
              width: 50px;
              height: 50px;
              transition: background 200ms ease-out;
              cursor: pointer;
              vertical-align: middle;
              text-align: center;
              text-transform: uppercase;
              a {
                display: inline;
                text-transform: none;
                color: $color-navy;
                font-size: 12px;
                text-align: #{$ldirection};
                img {
                  position: relative;
                  &.lazyload,
                  &.lazyloading {
                    visibility: hidden;
                  }
                }
              }
            }
          }
        }
      }
    }
    .blog-article__social-share--mob,
    .blog-article__social--pinterest {
      display: none;
    }
  }
  .blog-article__section {
    position: relative;
    width: 100%;
    &.blog-article__pinterest {
      text-align: center;
      .basic-img__wrapper {
        position: relative;
        display: inline-block;
        max-width: 100%;
        img {
          margin-bottom: 5.5%;
        }
      }
      .basic-carousel-formatter {
        .basic-carousel-formatter__slide {
          .basic-img__wrapper {
            img {
              margin-bottom: 2%;
            }
          }
        }
      }
    }
    &.blog-article__social--attach {
      @media screen and (max-width: $medium-max) {
        text-align: center;
        .basic-img__wrapper {
          display: inline-block;
        }
        .blog-article__social--pinterest {
          bottom: 13.6%;
        }
      }
      @media screen and (max-width: $max-mob-width) {
        .blog-article__social--pinterest {
          bottom: 15.6%;
          display: none;
        }
      }
      .blog-article__social-share--mob {
        top: auto;
        #{$rdirection}: 0;
        #{$ldirection}: auto;
        position: relative;
        text-align: #{$rdirection};
        margin-top: 0.6%;
        margin-bottom: 2.4%;
        li {
          display: inline-block;
          width: 24px;
          height: 24px;
          margin: 0 3px;
          a {
            line-height: 1;
          }
        }
      }
    }
    .blog-article__social--pinterest {
      @media screen and (max-width: $max-mob-width) {
        display: block;
      }
      display: none;
      position: absolute;
      bottom: 6.8%;
      max-width: 100%;
      #{$rdirection}: 10px;
      a {
        display: inline-block;
        width: 40px;
        height: 40px;
        img {
          max-width: 100%;
          border: 0;
        }
      }
    }
  }
  .social-pin-end {
    position: absolute;
    margin-top: -150px;
  }
  &__tag {
    @media screen and (max-width: $max-mob-width) {
      font-size: 2.5vw;
      letter-spacing: 0.18em;
      padding-bottom: 6.8%;
    }
    text-align: center;
    width: 100%;
    margin: 0 auto;
    font-size: 12px;
    letter-spacing: 0.14em;
    font-family: $akzidenz;
    text-transform: uppercase;
    padding-bottom: 3.2%;
    font-weight: 700;
    .blog-article__subtag {
      font-weight: 400;
    }
  }
  &__primarytag {
    &::after {
      content: ': ';
    }
  }
  &__header {
    @media screen and (max-width: $max-mob-width) {
      font-size: 6.25vw;
      letter-spacing: 0.02em;
      padding-bottom: 2.8%;
      max-width: 84.688%;
    }
    font-size: 36px;
    letter-spacing: 0.023em;
    width: 100%;
    text-align: center;
    font-family: $optima-book;
    color: $color-navy;
    padding-bottom: 1.2%;
    max-width: 64.686%;
    margin: 0 auto;
    a {
      cursor: pointer;
    }
  }
  &__teaser {
    @media screen and (max-width: $max-mob-width) {
      font-size: 3.75vw;
      letter-spacing: 0.018em;
      line-height: 1.46;
      padding-bottom: 4.5%;
      max-width: 84.688%;
    }
    font-size: 22px;
    letter-spacing: 0em;
    width: 100%;
    text-align: center;
    font-family: $optima-book;
    color: $color-navy;
    margin: 0 auto;
    padding-bottom: 2%;
    max-width: 64.686%;
  }
  &__drop-cap {
    @media screen and (max-width: $max-mob-width) {
      font-size: 10.3125vw;
      padding: 2.65625vw 0 0 0;
    }
    padding: 11px 0px 0 0;
    float: #{$ldirection};
    font-size: 58px;
    font-family: $optimalight;
    line-height: 0.4;
    color: $color-navy;
    html[dir='rtl'] & {
      float: #{$rdirection};
    }
  }
  .basic-textarea-v1 {
    @media screen and (max-width: $max-mob-width) {
      max-width: 84.688%;
    }
    max-width: 64.686%;
    margin: 0 auto;
    p {
      @media screen and (max-width: $max-mob-width) {
        font-size: 3.125vw;
        letter-spacing: 0.018em;
      }
      letter-spacing: 0.05em;
      line-height: 1.5;
      margin-top: 0;
      margin-bottom: 3%;
    }
    strong {
      letter-spacing: 0.057em;
    }
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  blockquote {
    @media screen and (max-width: $max-mob-width) {
      font-size: 3.75vw;
      line-height: 1.46;
      letter-spacing: 0.018em;
      margin: 5.5% auto;
    }
    font-size: 22px;
    font-family: $optima-book;
    text-align: center;
    color: $color-navy;
    margin: 2.5% auto;
    line-height: 1.2;
    letter-spacing: 0em;
  }
  .basic-img__wrapper {
    img {
      @media screen and (max-width: $max-mob-width) {
        max-width: 58.28125vw;
      }
      margin-bottom: 2.3%;
      max-width: 100%;
    }
    &:hover {
      .blog-article__social--pinterest {
        display: block;
      }
    }
  }
  &__video--yt,
  &__video--content {
    width: 100%;
    position: relative;
    overflow: hidden;
    margin: 4.7% 0;
    .blog-article__video--img {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

// Hiding the rogue blog-terms returned by Platform.
.field-blog-terms {
  display: none;
}
// Hiding the rogue publishing date returned by Platform.
.field-blog-publish-date {
  display: none;
}
